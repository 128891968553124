const c = [
	() => import("../../../src/routes/__layout.svelte"),
	() => import("../../../src/routes/__error.svelte"),
	() => import("../../../src/routes/index.svelte"),
	() => import("../../../src/routes/payment_messages/index.svelte"),
	() => import("../../../src/routes/activity_logs/index.svelte"),
	() => import("../../../src/routes/activity_logs/success/index.svelte"),
	() => import("../../../src/routes/activity_logs/fail/index.svelte"),
	() => import("../../../src/routes/activity_logs/fail/[jobId]/index.svelte"),
	() => import("../../../src/routes/activity_logs/pend/index.svelte"),
	() => import("../../../src/routes/activity_logs/run/index.svelte"),
	() => import("../../../src/routes/conversations/index.svelte"),
	() => import("../../../src/routes/conversations/active/index.svelte"),
	() => import("../../../src/routes/conversations/[platform]/[userId].svelte"),
	() => import("../../../src/routes/user_profiles/index.svelte"),
	() => import("../../../src/routes/user_profiles/[userId]/index.svelte"),
	() => import("../../../src/routes/user_profiles/[userId]/course_activities/index.svelte"),
	() => import("../../../src/routes/user_profiles/[userId]/certificates/index.svelte"),
	() => import("../../../src/routes/user_profiles/[userId]/assignments/index.svelte"),
	() => import("../../../src/routes/user_profiles/[userId]/feedbacks/index.svelte"),
	() => import("../../../src/routes/user_profiles/[userId]/lectures/index.svelte"),
	() => import("../../../src/routes/user_profiles/[userId]/quizzes/index.svelte"),
	() => import("../../../src/routes/questions/index.svelte"),
	() => import("../../../src/routes/questions/[questionId].svelte"),
	() => import("../../../src/routes/user_view/index.svelte"),
	() => import("../../../src/routes/user_view/[title]/view.svelte"),
	() => import("../../../src/routes/overview/index.svelte"),
	() => import("../../../src/routes/content/index.svelte"),
	() => import("../../../src/routes/content/assignments_redesign/index.svelte"),
	() => import("../../../src/routes/content/assignments_redesign/new.svelte"),
	() => import("../../../src/routes/content/assignments_redesign/[assignmentId]/edit.svelte"),
	() => import("../../../src/routes/content/assignments_redesign/[assignmentId]/view.svelte"),
	() => import("../../../src/routes/content/conversation_flows/index.svelte"),
	() => import("../../../src/routes/content/conversation_flows/[conversationFlowId]/view.svelte"),
	() => import("../../../src/routes/content/feedbacks_redesign/index.svelte"),
	() => import("../../../src/routes/content/feedbacks_redesign/new.svelte"),
	() => import("../../../src/routes/content/feedbacks_redesign/[feedbackId]/draft.svelte"),
	() => import("../../../src/routes/content/feedbacks_redesign/[feedbackId]/edit.svelte"),
	() => import("../../../src/routes/content/feedbacks_redesign/[feedbackId]/view.svelte"),
	() => import("../../../src/routes/content/quizzes_redesign/index.svelte"),
	() => import("../../../src/routes/content/quizzes_redesign/new.svelte"),
	() => import("../../../src/routes/content/quizzes_redesign/[quizName]/index.svelte"),
	() => import("../../../src/routes/content/quizzes_redesign/[quizName]/draft.svelte"),
	() => import("../../../src/routes/content/quizzes_redesign/[quizName]/edit.svelte"),
	() => import("../../../src/routes/content/quizzes_redesign/[quizName]/view.svelte"),
	() => import("../../../src/routes/content/messenger_links/index.svelte"),
	() => import("../../../src/routes/content/messenger_links/new.svelte"),
	() => import("../../../src/routes/content/messenger_links/[messengerLinkId]/view.svelte"),
	() => import("../../../src/routes/content/button_menus/index.svelte"),
	() => import("../../../src/routes/content/button_menus/new.svelte"),
	() => import("../../../src/routes/content/button_menus/[buttonMenuId]/index.svelte"),
	() => import("../../../src/routes/content/button_menus/[buttonMenuId]/edit.svelte"),
	() => import("../../../src/routes/content/button_menus/[buttonMenuId]/view.svelte"),
	() => import("../../../src/routes/content/assignments/[assignmentId]/index.svelte"),
	() => import("../../../src/routes/content/assignments/[assignmentId]/edit_data.svelte"),
	() => import("../../../src/routes/content/assignments/[assignmentId]/edit.svelte"),
	() => import("../../../src/routes/content/assignments/[assignmentId]/view.svelte"),
	() => import("../../../src/routes/content/certificate/index.svelte"),
	() => import("../../../src/routes/content/certificate/redesign/new.svelte"),
	() => import("../../../src/routes/content/card_lists/index.svelte"),
	() => import("../../../src/routes/content/card_lists/new.svelte"),
	() => import("../../../src/routes/content/card_lists/[cardId]/edit.svelte"),
	() => import("../../../src/routes/content/card_lists/[cardId]/view.svelte"),
	() => import("../../../src/routes/content/feedbacks/index.svelte"),
	() => import("../../../src/routes/content/feedbacks/new.svelte"),
	() => import("../../../src/routes/content/feedbacks/[feedbackId]/index.svelte"),
	() => import("../../../src/routes/content/feedbacks/[feedbackId]/edit.svelte"),
	() => import("../../../src/routes/content/feedbacks/[feedbackId]/view.svelte"),
	() => import("../../../src/routes/content/lectures/index.svelte"),
	() => import("../../../src/routes/content/lectures/new.svelte"),
	() => import("../../../src/routes/content/lectures/[lectureName]/index.svelte"),
	() => import("../../../src/routes/content/lectures/[lectureName]/edit.svelte"),
	() => import("../../../src/routes/content/lectures/[lectureName]/view.svelte"),
	() => import("../../../src/routes/content/projects/index.svelte"),
	() => import("../../../src/routes/content/projects/new.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/index.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/discussion_participations/index.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/discussion_participations/new.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/discussion_participations/[discussionParticipationId]/edit.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/discussion_participations/[discussionParticipationId]/view.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/exclusive_opportunities/index.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/assignments_redesign/index.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/assignments_redesign/new.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/feedbacks_redesign/index.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/feedbacks_redesign/new.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/scheduled_messages/index.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/scheduled_messages/new.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/quizzes_redesign/new.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/registrations/index.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/registrations/new.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/registrations/[formName]/edit.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/registrations/[formName]/view.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/aid_requests/index.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/aid_requests/[requestId].svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/class_groups/index.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/class_groups/import.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/class_groups/new.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/class_groups/[classGroupId]/edit.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/assignments/index.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/assignments/new.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/attendances/index.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/attendances/new.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/attendances/[attendanceId]/edit.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/attendances/[attendanceId]/view.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/certificate/index.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/certificate/redesign/new.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/certificate/new.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/feedbacks/index.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/feedbacks/new.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/payments/index.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/batches/index.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/batches/new.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/batches/[batchId]/edit.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/batches/[batchId]/view.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/blogs/new.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/edit.svelte"),
	() => import("../../../src/routes/content/projects/[projectId]/view.svelte"),
	() => import("../../../src/routes/content/courses/index.svelte"),
	() => import("../../../src/routes/content/courses/pages/section1.svelte"),
	() => import("../../../src/routes/content/courses/pages/section2.svelte"),
	() => import("../../../src/routes/content/courses/pages/section3.svelte"),
	() => import("../../../src/routes/content/courses/[courseId]/edit.svelte"),
	() => import("../../../src/routes/content/lessons/[projectId]/edit.svelte"),
	() => import("../../../src/routes/content/lessons/[projectId]/new.svelte"),
	() => import("../../../src/routes/content/quizzes/index.svelte"),
	() => import("../../../src/routes/content/quizzes/new.svelte"),
	() => import("../../../src/routes/content/quizzes/[quizName]/index.svelte"),
	() => import("../../../src/routes/content/quizzes/[quizName]/edit.svelte"),
	() => import("../../../src/routes/content/quizzes/[quizName]/view.svelte"),
	() => import("../../../src/routes/content/blogs/index.svelte"),
	() => import("../../../src/routes/content/blogs/new.svelte"),
	() => import("../../../src/routes/content/blogs/[blogId]/edit.svelte"),
	() => import("../../../src/routes/content/blogs/[blogId]/view.svelte"),
	() => import("../../../src/routes/content/cards/index.svelte"),
	() => import("../../../src/routes/content/cards/new.svelte"),
	() => import("../../../src/routes/content/cards/[cardId]/edit.svelte"),
	() => import("../../../src/routes/content/cards/[cardId]/view.svelte"),
	() => import("../../../src/routes/content/cards/[cardId]/[contentId]/index.svelte"),
	() => import("../../../src/routes/config/get_started/index.svelte"),
	() => import("../../../src/routes/config/get_started/new.svelte"),
	() => import("../../../src/routes/config/get_started/[itemId]/edit.svelte"),
	() => import("../../../src/routes/config/get_started/[itemId]/view.svelte"),
	() => import("../../../src/routes/admin/index.svelte"),
	() => import("../../../src/routes/admin/pages/index.svelte"),
	() => import("../../../src/routes/admin/pages/new.svelte"),
	() => import("../../../src/routes/admin/pages/[pageId]/edit.svelte"),
	() => import("../../../src/routes/admin/pages/[pageId]/view.svelte"),
	() => import("../../../src/routes/login/__layout.reset.svelte"),
	() => import("../../../src/routes/login/index.svelte")
];

const d = decodeURIComponent;

export const routes = [
	// src/routes/index.svelte
	[/^\/$/, [c[0], c[2]], [c[1]]],

	// src/routes/payment_messages/index.svelte
	[/^\/payment_messages\/?$/, [c[0], c[3]], [c[1]]],

	// src/routes/activity_logs/index.svelte
	[/^\/activity_logs\/?$/, [c[0], c[4]], [c[1]]],

	// src/routes/activity_logs/success/index.svelte
	[/^\/activity_logs\/success\/?$/, [c[0], c[5]], [c[1]]],

	// src/routes/activity_logs/fail/index.svelte
	[/^\/activity_logs\/fail\/?$/, [c[0], c[6]], [c[1]]],

	// src/routes/activity_logs/fail/[jobId]/index.svelte
	[/^\/activity_logs\/fail\/([^/]+?)\/?$/, [c[0], c[7]], [c[1]], (m) => ({ jobId: d(m[1])})],

	// src/routes/activity_logs/pend/index.svelte
	[/^\/activity_logs\/pend\/?$/, [c[0], c[8]], [c[1]]],

	// src/routes/activity_logs/run/index.svelte
	[/^\/activity_logs\/run\/?$/, [c[0], c[9]], [c[1]]],

	// src/routes/conversations/index.svelte
	[/^\/conversations\/?$/, [c[0], c[10]], [c[1]]],

	// src/routes/conversations/active/index.svelte
	[/^\/conversations\/active\/?$/, [c[0], c[11]], [c[1]]],

	// src/routes/conversations/[platform]/[userId].svelte
	[/^\/conversations\/([^/]+?)\/([^/]+?)\/?$/, [c[0], c[12]], [c[1]], (m) => ({ platform: d(m[1]), userId: d(m[2])})],

	// src/routes/user_profiles/index.svelte
	[/^\/user_profiles\/?$/, [c[0], c[13]], [c[1]]],

	// src/routes/user_profiles/[userId]/index.svelte
	[/^\/user_profiles\/([^/]+?)\/?$/, [c[0], c[14]], [c[1]], (m) => ({ userId: d(m[1])})],

	// src/routes/user_profiles/[userId]/course_activities/index.svelte
	[/^\/user_profiles\/([^/]+?)\/course_activities\/?$/, [c[0], c[15]], [c[1]], (m) => ({ userId: d(m[1])})],

	// src/routes/user_profiles/[userId]/certificates/index.svelte
	[/^\/user_profiles\/([^/]+?)\/certificates\/?$/, [c[0], c[16]], [c[1]], (m) => ({ userId: d(m[1])})],

	// src/routes/user_profiles/[userId]/assignments/index.svelte
	[/^\/user_profiles\/([^/]+?)\/assignments\/?$/, [c[0], c[17]], [c[1]], (m) => ({ userId: d(m[1])})],

	// src/routes/user_profiles/[userId]/feedbacks/index.svelte
	[/^\/user_profiles\/([^/]+?)\/feedbacks\/?$/, [c[0], c[18]], [c[1]], (m) => ({ userId: d(m[1])})],

	// src/routes/user_profiles/[userId]/lectures/index.svelte
	[/^\/user_profiles\/([^/]+?)\/lectures\/?$/, [c[0], c[19]], [c[1]], (m) => ({ userId: d(m[1])})],

	// src/routes/user_profiles/[userId]/quizzes/index.svelte
	[/^\/user_profiles\/([^/]+?)\/quizzes\/?$/, [c[0], c[20]], [c[1]], (m) => ({ userId: d(m[1])})],

	// src/routes/questions/index.svelte
	[/^\/questions\/?$/, [c[0], c[21]], [c[1]]],

	// src/routes/questions/[questionId].svelte
	[/^\/questions\/([^/]+?)\/?$/, [c[0], c[22]], [c[1]], (m) => ({ questionId: d(m[1])})],

	// src/routes/user_view/index.svelte
	[/^\/user_view\/?$/, [c[0], c[23]], [c[1]]],

	// src/routes/user_view/[title]/view.svelte
	[/^\/user_view\/([^/]+?)\/view\/?$/, [c[0], c[24]], [c[1]], (m) => ({ title: d(m[1])})],

	// src/routes/overview/index.svelte
	[/^\/overview\/?$/, [c[0], c[25]], [c[1]]],

	// src/routes/content/index.svelte
	[/^\/content\/?$/, [c[0], c[26]], [c[1]]],

	// src/routes/content/assignments_redesign/index.svelte
	[/^\/content\/assignments_redesign\/?$/, [c[0], c[27]], [c[1]]],

	// src/routes/content/assignments_redesign/new.svelte
	[/^\/content\/assignments_redesign\/new\/?$/, [c[0], c[28]], [c[1]]],

	// src/routes/content/assignments_redesign/[assignmentId]/edit.svelte
	[/^\/content\/assignments_redesign\/([^/]+?)\/edit\/?$/, [c[0], c[29]], [c[1]], (m) => ({ assignmentId: d(m[1])})],

	// src/routes/content/assignments_redesign/[assignmentId]/view.svelte
	[/^\/content\/assignments_redesign\/([^/]+?)\/view\/?$/, [c[0], c[30]], [c[1]], (m) => ({ assignmentId: d(m[1])})],

	// src/routes/content/conversation_flows/index.svelte
	[/^\/content\/conversation_flows\/?$/, [c[0], c[31]], [c[1]]],

	// src/routes/content/conversation_flows/[conversationFlowId]/view.svelte
	[/^\/content\/conversation_flows\/([^/]+?)\/view\/?$/, [c[0], c[32]], [c[1]], (m) => ({ conversationFlowId: d(m[1])})],

	// src/routes/content/feedbacks_redesign/index.svelte
	[/^\/content\/feedbacks_redesign\/?$/, [c[0], c[33]], [c[1]]],

	// src/routes/content/feedbacks_redesign/new.svelte
	[/^\/content\/feedbacks_redesign\/new\/?$/, [c[0], c[34]], [c[1]]],

	// src/routes/content/feedbacks_redesign/[feedbackId]/draft.svelte
	[/^\/content\/feedbacks_redesign\/([^/]+?)\/draft\/?$/, [c[0], c[35]], [c[1]], (m) => ({ feedbackId: d(m[1])})],

	// src/routes/content/feedbacks_redesign/[feedbackId]/edit.svelte
	[/^\/content\/feedbacks_redesign\/([^/]+?)\/edit\/?$/, [c[0], c[36]], [c[1]], (m) => ({ feedbackId: d(m[1])})],

	// src/routes/content/feedbacks_redesign/[feedbackId]/view.svelte
	[/^\/content\/feedbacks_redesign\/([^/]+?)\/view\/?$/, [c[0], c[37]], [c[1]], (m) => ({ feedbackId: d(m[1])})],

	// src/routes/content/quizzes_redesign/index.svelte
	[/^\/content\/quizzes_redesign\/?$/, [c[0], c[38]], [c[1]]],

	// src/routes/content/quizzes_redesign/new.svelte
	[/^\/content\/quizzes_redesign\/new\/?$/, [c[0], c[39]], [c[1]]],

	// src/routes/content/quizzes_redesign/[quizName]/index.svelte
	[/^\/content\/quizzes_redesign\/([^/]+?)\/?$/, [c[0], c[40]], [c[1]], (m) => ({ quizName: d(m[1])})],

	// src/routes/content/quizzes_redesign/[quizName]/draft.svelte
	[/^\/content\/quizzes_redesign\/([^/]+?)\/draft\/?$/, [c[0], c[41]], [c[1]], (m) => ({ quizName: d(m[1])})],

	// src/routes/content/quizzes_redesign/[quizName]/edit.svelte
	[/^\/content\/quizzes_redesign\/([^/]+?)\/edit\/?$/, [c[0], c[42]], [c[1]], (m) => ({ quizName: d(m[1])})],

	// src/routes/content/quizzes_redesign/[quizName]/view.svelte
	[/^\/content\/quizzes_redesign\/([^/]+?)\/view\/?$/, [c[0], c[43]], [c[1]], (m) => ({ quizName: d(m[1])})],

	// src/routes/content/messenger_links/index.svelte
	[/^\/content\/messenger_links\/?$/, [c[0], c[44]], [c[1]]],

	// src/routes/content/messenger_links/new.svelte
	[/^\/content\/messenger_links\/new\/?$/, [c[0], c[45]], [c[1]]],

	// src/routes/content/messenger_links/[messengerLinkId]/view.svelte
	[/^\/content\/messenger_links\/([^/]+?)\/view\/?$/, [c[0], c[46]], [c[1]], (m) => ({ messengerLinkId: d(m[1])})],

	// src/routes/content/button_menus/index.svelte
	[/^\/content\/button_menus\/?$/, [c[0], c[47]], [c[1]]],

	// src/routes/content/button_menus/new.svelte
	[/^\/content\/button_menus\/new\/?$/, [c[0], c[48]], [c[1]]],

	// src/routes/content/button_menus/[buttonMenuId]/index.svelte
	[/^\/content\/button_menus\/([^/]+?)\/?$/, [c[0], c[49]], [c[1]], (m) => ({ buttonMenuId: d(m[1])})],

	// src/routes/content/button_menus/[buttonMenuId]/edit.svelte
	[/^\/content\/button_menus\/([^/]+?)\/edit\/?$/, [c[0], c[50]], [c[1]], (m) => ({ buttonMenuId: d(m[1])})],

	// src/routes/content/button_menus/[buttonMenuId]/view.svelte
	[/^\/content\/button_menus\/([^/]+?)\/view\/?$/, [c[0], c[51]], [c[1]], (m) => ({ buttonMenuId: d(m[1])})],

	// src/routes/content/assignments/[assignmentId]/index.svelte
	[/^\/content\/assignments\/([^/]+?)\/?$/, [c[0], c[52]], [c[1]], (m) => ({ assignmentId: d(m[1])})],

	// src/routes/content/assignments/[assignmentId]/edit_data.svelte
	[/^\/content\/assignments\/([^/]+?)\/edit_data\/?$/, [c[0], c[53]], [c[1]], (m) => ({ assignmentId: d(m[1])})],

	// src/routes/content/assignments/[assignmentId]/edit.svelte
	[/^\/content\/assignments\/([^/]+?)\/edit\/?$/, [c[0], c[54]], [c[1]], (m) => ({ assignmentId: d(m[1])})],

	// src/routes/content/assignments/[assignmentId]/view.svelte
	[/^\/content\/assignments\/([^/]+?)\/view\/?$/, [c[0], c[55]], [c[1]], (m) => ({ assignmentId: d(m[1])})],

	// src/routes/content/certificate/index.svelte
	[/^\/content\/certificate\/?$/, [c[0], c[56]], [c[1]]],

	// src/routes/content/certificate/redesign/new.svelte
	[/^\/content\/certificate\/redesign\/new\/?$/, [c[0], c[57]], [c[1]]],

	// src/routes/content/card_lists/index.svelte
	[/^\/content\/card_lists\/?$/, [c[0], c[58]], [c[1]]],

	// src/routes/content/card_lists/new.svelte
	[/^\/content\/card_lists\/new\/?$/, [c[0], c[59]], [c[1]]],

	// src/routes/content/card_lists/[cardId]/edit.svelte
	[/^\/content\/card_lists\/([^/]+?)\/edit\/?$/, [c[0], c[60]], [c[1]], (m) => ({ cardId: d(m[1])})],

	// src/routes/content/card_lists/[cardId]/view.svelte
	[/^\/content\/card_lists\/([^/]+?)\/view\/?$/, [c[0], c[61]], [c[1]], (m) => ({ cardId: d(m[1])})],

	// src/routes/content/feedbacks/index.svelte
	[/^\/content\/feedbacks\/?$/, [c[0], c[62]], [c[1]]],

	// src/routes/content/feedbacks/new.svelte
	[/^\/content\/feedbacks\/new\/?$/, [c[0], c[63]], [c[1]]],

	// src/routes/content/feedbacks/[feedbackId]/index.svelte
	[/^\/content\/feedbacks\/([^/]+?)\/?$/, [c[0], c[64]], [c[1]], (m) => ({ feedbackId: d(m[1])})],

	// src/routes/content/feedbacks/[feedbackId]/edit.svelte
	[/^\/content\/feedbacks\/([^/]+?)\/edit\/?$/, [c[0], c[65]], [c[1]], (m) => ({ feedbackId: d(m[1])})],

	// src/routes/content/feedbacks/[feedbackId]/view.svelte
	[/^\/content\/feedbacks\/([^/]+?)\/view\/?$/, [c[0], c[66]], [c[1]], (m) => ({ feedbackId: d(m[1])})],

	// src/routes/content/lectures/index.svelte
	[/^\/content\/lectures\/?$/, [c[0], c[67]], [c[1]]],

	// src/routes/content/lectures/new.svelte
	[/^\/content\/lectures\/new\/?$/, [c[0], c[68]], [c[1]]],

	// src/routes/content/lectures/[lectureName]/index.svelte
	[/^\/content\/lectures\/([^/]+?)\/?$/, [c[0], c[69]], [c[1]], (m) => ({ lectureName: d(m[1])})],

	// src/routes/content/lectures/[lectureName]/edit.svelte
	[/^\/content\/lectures\/([^/]+?)\/edit\/?$/, [c[0], c[70]], [c[1]], (m) => ({ lectureName: d(m[1])})],

	// src/routes/content/lectures/[lectureName]/view.svelte
	[/^\/content\/lectures\/([^/]+?)\/view\/?$/, [c[0], c[71]], [c[1]], (m) => ({ lectureName: d(m[1])})],

	// src/routes/content/projects/index.svelte
	[/^\/content\/projects\/?$/, [c[0], c[72]], [c[1]]],

	// src/routes/content/projects/new.svelte
	[/^\/content\/projects\/new\/?$/, [c[0], c[73]], [c[1]]],

	// src/routes/content/projects/[projectId]/index.svelte
	[/^\/content\/projects\/([^/]+?)\/?$/, [c[0], c[74]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/discussion_participations/index.svelte
	[/^\/content\/projects\/([^/]+?)\/discussion_participations\/?$/, [c[0], c[75]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/discussion_participations/new.svelte
	[/^\/content\/projects\/([^/]+?)\/discussion_participations\/new\/?$/, [c[0], c[76]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/discussion_participations/[discussionParticipationId]/edit.svelte
	[/^\/content\/projects\/([^/]+?)\/discussion_participations\/([^/]+?)\/edit\/?$/, [c[0], c[77]], [c[1]], (m) => ({ projectId: d(m[1]), discussionParticipationId: d(m[2])})],

	// src/routes/content/projects/[projectId]/discussion_participations/[discussionParticipationId]/view.svelte
	[/^\/content\/projects\/([^/]+?)\/discussion_participations\/([^/]+?)\/view\/?$/, [c[0], c[78]], [c[1]], (m) => ({ projectId: d(m[1]), discussionParticipationId: d(m[2])})],

	// src/routes/content/projects/[projectId]/exclusive_opportunities/index.svelte
	[/^\/content\/projects\/([^/]+?)\/exclusive_opportunities\/?$/, [c[0], c[79]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/assignments_redesign/index.svelte
	[/^\/content\/projects\/([^/]+?)\/assignments_redesign\/?$/, [c[0], c[80]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/assignments_redesign/new.svelte
	[/^\/content\/projects\/([^/]+?)\/assignments_redesign\/new\/?$/, [c[0], c[81]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/feedbacks_redesign/index.svelte
	[/^\/content\/projects\/([^/]+?)\/feedbacks_redesign\/?$/, [c[0], c[82]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/feedbacks_redesign/new.svelte
	[/^\/content\/projects\/([^/]+?)\/feedbacks_redesign\/new\/?$/, [c[0], c[83]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/scheduled_messages/index.svelte
	[/^\/content\/projects\/([^/]+?)\/scheduled_messages\/?$/, [c[0], c[84]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/scheduled_messages/new.svelte
	[/^\/content\/projects\/([^/]+?)\/scheduled_messages\/new\/?$/, [c[0], c[85]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/quizzes_redesign/new.svelte
	[/^\/content\/projects\/([^/]+?)\/quizzes_redesign\/new\/?$/, [c[0], c[86]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/registrations/index.svelte
	[/^\/content\/projects\/([^/]+?)\/registrations\/?$/, [c[0], c[87]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/registrations/new.svelte
	[/^\/content\/projects\/([^/]+?)\/registrations\/new\/?$/, [c[0], c[88]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/registrations/[formName]/edit.svelte
	[/^\/content\/projects\/([^/]+?)\/registrations\/([^/]+?)\/edit\/?$/, [c[0], c[89]], [c[1]], (m) => ({ projectId: d(m[1]), formName: d(m[2])})],

	// src/routes/content/projects/[projectId]/registrations/[formName]/view.svelte
	[/^\/content\/projects\/([^/]+?)\/registrations\/([^/]+?)\/view\/?$/, [c[0], c[90]], [c[1]], (m) => ({ projectId: d(m[1]), formName: d(m[2])})],

	// src/routes/content/projects/[projectId]/aid_requests/index.svelte
	[/^\/content\/projects\/([^/]+?)\/aid_requests\/?$/, [c[0], c[91]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/aid_requests/[requestId].svelte
	[/^\/content\/projects\/([^/]+?)\/aid_requests\/([^/]+?)\/?$/, [c[0], c[92]], [c[1]], (m) => ({ projectId: d(m[1]), requestId: d(m[2])})],

	// src/routes/content/projects/[projectId]/class_groups/index.svelte
	[/^\/content\/projects\/([^/]+?)\/class_groups\/?$/, [c[0], c[93]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/class_groups/import.svelte
	[/^\/content\/projects\/([^/]+?)\/class_groups\/import\/?$/, [c[0], c[94]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/class_groups/new.svelte
	[/^\/content\/projects\/([^/]+?)\/class_groups\/new\/?$/, [c[0], c[95]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/class_groups/[classGroupId]/edit.svelte
	[/^\/content\/projects\/([^/]+?)\/class_groups\/([^/]+?)\/edit\/?$/, [c[0], c[96]], [c[1]], (m) => ({ projectId: d(m[1]), classGroupId: d(m[2])})],

	// src/routes/content/projects/[projectId]/assignments/index.svelte
	[/^\/content\/projects\/([^/]+?)\/assignments\/?$/, [c[0], c[97]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/assignments/new.svelte
	[/^\/content\/projects\/([^/]+?)\/assignments\/new\/?$/, [c[0], c[98]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/attendances/index.svelte
	[/^\/content\/projects\/([^/]+?)\/attendances\/?$/, [c[0], c[99]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/attendances/new.svelte
	[/^\/content\/projects\/([^/]+?)\/attendances\/new\/?$/, [c[0], c[100]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/attendances/[attendanceId]/edit.svelte
	[/^\/content\/projects\/([^/]+?)\/attendances\/([^/]+?)\/edit\/?$/, [c[0], c[101]], [c[1]], (m) => ({ projectId: d(m[1]), attendanceId: d(m[2])})],

	// src/routes/content/projects/[projectId]/attendances/[attendanceId]/view.svelte
	[/^\/content\/projects\/([^/]+?)\/attendances\/([^/]+?)\/view\/?$/, [c[0], c[102]], [c[1]], (m) => ({ projectId: d(m[1]), attendanceId: d(m[2])})],

	// src/routes/content/projects/[projectId]/certificate/index.svelte
	[/^\/content\/projects\/([^/]+?)\/certificate\/?$/, [c[0], c[103]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/certificate/redesign/new.svelte
	[/^\/content\/projects\/([^/]+?)\/certificate\/redesign\/new\/?$/, [c[0], c[104]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/certificate/new.svelte
	[/^\/content\/projects\/([^/]+?)\/certificate\/new\/?$/, [c[0], c[105]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/feedbacks/index.svelte
	[/^\/content\/projects\/([^/]+?)\/feedbacks\/?$/, [c[0], c[106]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/feedbacks/new.svelte
	[/^\/content\/projects\/([^/]+?)\/feedbacks\/new\/?$/, [c[0], c[107]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/payments/index.svelte
	[/^\/content\/projects\/([^/]+?)\/payments\/?$/, [c[0], c[108]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/batches/index.svelte
	[/^\/content\/projects\/([^/]+?)\/batches\/?$/, [c[0], c[109]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/batches/new.svelte
	[/^\/content\/projects\/([^/]+?)\/batches\/new\/?$/, [c[0], c[110]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/batches/[batchId]/edit.svelte
	[/^\/content\/projects\/([^/]+?)\/batches\/([^/]+?)\/edit\/?$/, [c[0], c[111]], [c[1]], (m) => ({ projectId: d(m[1]), batchId: d(m[2])})],

	// src/routes/content/projects/[projectId]/batches/[batchId]/view.svelte
	[/^\/content\/projects\/([^/]+?)\/batches\/([^/]+?)\/view\/?$/, [c[0], c[112]], [c[1]], (m) => ({ projectId: d(m[1]), batchId: d(m[2])})],

	// src/routes/content/projects/[projectId]/blogs/new.svelte
	[/^\/content\/projects\/([^/]+?)\/blogs\/new\/?$/, [c[0], c[113]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/edit.svelte
	[/^\/content\/projects\/([^/]+?)\/edit\/?$/, [c[0], c[114]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/projects/[projectId]/view.svelte
	[/^\/content\/projects\/([^/]+?)\/view\/?$/, [c[0], c[115]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/courses/index.svelte
	[/^\/content\/courses\/?$/, [c[0], c[116]], [c[1]]],

	// src/routes/content/courses/pages/section1.svelte
	[/^\/content\/courses\/pages\/section1\/?$/, [c[0], c[117]], [c[1]]],

	// src/routes/content/courses/pages/section2.svelte
	[/^\/content\/courses\/pages\/section2\/?$/, [c[0], c[118]], [c[1]]],

	// src/routes/content/courses/pages/section3.svelte
	[/^\/content\/courses\/pages\/section3\/?$/, [c[0], c[119]], [c[1]]],

	// src/routes/content/courses/[courseId]/edit.svelte
	[/^\/content\/courses\/([^/]+?)\/edit\/?$/, [c[0], c[120]], [c[1]], (m) => ({ courseId: d(m[1])})],

	// src/routes/content/lessons/[projectId]/edit.svelte
	[/^\/content\/lessons\/([^/]+?)\/edit\/?$/, [c[0], c[121]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/lessons/[projectId]/new.svelte
	[/^\/content\/lessons\/([^/]+?)\/new\/?$/, [c[0], c[122]], [c[1]], (m) => ({ projectId: d(m[1])})],

	// src/routes/content/quizzes/index.svelte
	[/^\/content\/quizzes\/?$/, [c[0], c[123]], [c[1]]],

	// src/routes/content/quizzes/new.svelte
	[/^\/content\/quizzes\/new\/?$/, [c[0], c[124]], [c[1]]],

	// src/routes/content/quizzes/[quizName]/index.svelte
	[/^\/content\/quizzes\/([^/]+?)\/?$/, [c[0], c[125]], [c[1]], (m) => ({ quizName: d(m[1])})],

	// src/routes/content/quizzes/[quizName]/edit.svelte
	[/^\/content\/quizzes\/([^/]+?)\/edit\/?$/, [c[0], c[126]], [c[1]], (m) => ({ quizName: d(m[1])})],

	// src/routes/content/quizzes/[quizName]/view.svelte
	[/^\/content\/quizzes\/([^/]+?)\/view\/?$/, [c[0], c[127]], [c[1]], (m) => ({ quizName: d(m[1])})],

	// src/routes/content/blogs/index.svelte
	[/^\/content\/blogs\/?$/, [c[0], c[128]], [c[1]]],

	// src/routes/content/blogs/new.svelte
	[/^\/content\/blogs\/new\/?$/, [c[0], c[129]], [c[1]]],

	// src/routes/content/blogs/[blogId]/edit.svelte
	[/^\/content\/blogs\/([^/]+?)\/edit\/?$/, [c[0], c[130]], [c[1]], (m) => ({ blogId: d(m[1])})],

	// src/routes/content/blogs/[blogId]/view.svelte
	[/^\/content\/blogs\/([^/]+?)\/view\/?$/, [c[0], c[131]], [c[1]], (m) => ({ blogId: d(m[1])})],

	// src/routes/content/cards/index.svelte
	[/^\/content\/cards\/?$/, [c[0], c[132]], [c[1]]],

	// src/routes/content/cards/new.svelte
	[/^\/content\/cards\/new\/?$/, [c[0], c[133]], [c[1]]],

	// src/routes/content/cards/[cardId]/edit.svelte
	[/^\/content\/cards\/([^/]+?)\/edit\/?$/, [c[0], c[134]], [c[1]], (m) => ({ cardId: d(m[1])})],

	// src/routes/content/cards/[cardId]/view.svelte
	[/^\/content\/cards\/([^/]+?)\/view\/?$/, [c[0], c[135]], [c[1]], (m) => ({ cardId: d(m[1])})],

	// src/routes/content/cards/[cardId]/[contentId]/index.svelte
	[/^\/content\/cards\/([^/]+?)\/([^/]+?)\/?$/, [c[0], c[136]], [c[1]], (m) => ({ cardId: d(m[1]), contentId: d(m[2])})],

	// src/routes/config/get_started/index.svelte
	[/^\/config\/get_started\/?$/, [c[0], c[137]], [c[1]]],

	// src/routes/config/get_started/new.svelte
	[/^\/config\/get_started\/new\/?$/, [c[0], c[138]], [c[1]]],

	// src/routes/config/get_started/[itemId]/edit.svelte
	[/^\/config\/get_started\/([^/]+?)\/edit\/?$/, [c[0], c[139]], [c[1]], (m) => ({ itemId: d(m[1])})],

	// src/routes/config/get_started/[itemId]/view.svelte
	[/^\/config\/get_started\/([^/]+?)\/view\/?$/, [c[0], c[140]], [c[1]], (m) => ({ itemId: d(m[1])})],

	// src/routes/admin/index.svelte
	[/^\/admin\/?$/, [c[0], c[141]], [c[1]]],

	// src/routes/admin/pages/index.svelte
	[/^\/admin\/pages\/?$/, [c[0], c[142]], [c[1]]],

	// src/routes/admin/pages/new.svelte
	[/^\/admin\/pages\/new\/?$/, [c[0], c[143]], [c[1]]],

	// src/routes/admin/pages/[pageId]/edit.svelte
	[/^\/admin\/pages\/([^/]+?)\/edit\/?$/, [c[0], c[144]], [c[1]], (m) => ({ pageId: d(m[1])})],

	// src/routes/admin/pages/[pageId]/view.svelte
	[/^\/admin\/pages\/([^/]+?)\/view\/?$/, [c[0], c[145]], [c[1]], (m) => ({ pageId: d(m[1])})],

	// src/routes/login/index.svelte
	[/^\/login\/?$/, [c[146], c[147]], []]
];

// we import the root layout/error components eagerly, so that
// connectivity errors after initialisation don't nuke the app
export const fallback = [c[0](), c[1]()];